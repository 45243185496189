import { Module, VuexModule, Mutation } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

@Module
export default class MiscModule extends VuexModule {
  calendarScrollTop = "";

  get getCalendarScrollTop(): string {
    return this.calendarScrollTop;
  }

  @Mutation
  [Mutations.SET_CALENDAR_SCROLL_TOP](calendarScrollTop: string) {
    this.calendarScrollTop = calendarScrollTop;
  }
}
