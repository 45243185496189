import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { hasPermissions } from "../auth/utils";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/forms/missedCall",
        name: "missedCall",
        component: () => import("@/views/Forms/MissedCall.vue"),
      },
      {
        path: "/forms/nonComplianceImprovement",
        name: "nonComplianceImprovement",
        component: () => import("@/views/Forms/NonComplianceImprovement.vue"),
      },
      {
        path: "/forms/vacation",
        name: "vacation",
        component: () => import("@/views/Forms/Vacation.vue"),
      },
      {
        path: "/staff/paidVacation",
        name: "paidVacation",
        component: () => import("@/views/Staff/PaidVacation.vue"),
      },
      {
        path: "/billing/debtors",
        name: "debtors",
        component: () => import("@/views/Billing/Debtors.vue"),
      },
      {
        path: "/business/affaires",
        name: "affaires",
        component: () => import("@/views/Business/Affaires.vue"),
      },
      {
        path: "/business/ongoingCases",
        name: "ongoingCases",
        component: () => import("@/views/Business/OngoingCases.vue"),
      },
      {
        path: "/business/count/:idAffaire",
        name: "debtorsCount",
        params: true,
        component: () => import("@/views/Business/Count.vue"),
      },
      {
        path: "/analysis/bill",
        name: "bill",
        component: () => import("@/views/Analysis/Bill.vue"),
      },
      {
        path: "/calendar",
        name: "calendar",
        component: () => import("@/views/Calendar/Calendar.vue"),
      },
      {
        path: "/geodesis/employees",
        name: "employees",
        component: () => import("@/views/Geodesis/Employees.vue"),
      },
      {
        path: "/geodesis/aq",
        name: "aq",
        component: () => import("@/views/Geodesis/AQ.vue"),
      },
      {
        path: "/geodesis/externalLinks",
        name: "externalLinks",
        component: () => import("@/views/Geodesis/ExternalLinks.vue"),
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/Users/Users.vue"),
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, _, next) => {
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (to.path === "/" || to.path.indexOf("dashboard") !== -1) {
    return next();
  }
  const currentUser = store.getters.currentUser;
  if (!currentUser || !hasPermissions(currentUser, to.path)) {
    //return next({ name: "dashboard" });
    return next();
  }

  return next();
});

export default router;
