export const hasPermissions = (currentUser, route) => {
  if (
    !currentUser ||
    !currentUser.droitUtilisateur ||
    currentUser.droitUtilisateur.length === 0
  ) {
    return false;
  }

  const rights = currentUser.droitUtilisateur;
  if (route.indexOf("billing") !== -1 || route.indexOf("analysis") !== -1) {
    return currentUser.isAdmin;
  }

  if (route.indexOf("ongoingCases") !== -1 || route.indexOf("count") !== -1) {
    return (
      currentUser.isAdmin || rights.indexOf("ImpressionJournalEncours") !== -1
    );
  }

  return true;
};
