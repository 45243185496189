<template>
  <router-view />
</template>

<script>
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { mapMutations } from "vuex";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const account = ref(null);

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });

    return {
      account,
      store,
    };
  },
  methods: {
    ...mapMutations(["setAccessToken"]),
    getCurrentUser() {
      axios
        .get(`/softgestion/GetCurrentUser?username=${this.account.username}`)
        .then((response) => {
          if (response && response.data) {
            this.store.commit(Mutations.SET_USER, response.data);
            this.store.commit(
              Mutations.SET_CURRENT_GROUPE_AFFAIRE_ID,
              response.data.idGroupeAffaire
            );
          } else {
            this.setError();
          }
        })
        .catch(this.setError);
    },
    setError() {
      this.store.commit(Mutations.SET_ERROR, {
        noUser:
          "Impossible de retrouver cette utilisateur. Veuillez vous connecter avec un autre utilisateur.",
      });
    },
  },
  async mounted() {
    await this.$msalInstance.handleRedirectPromise();
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      this.$msalInstance.loginRedirect();
      return;
    }
    this.account = accounts[0];
    this.$emitter.emit("login", this.account);
    this.getCurrentUser();
    console.log(ref(this.account));
    let request = {
      scopes: ["Calendars.Read", "Calendars.Read.Shared"],
      account: ref(this.account),
    };
    const msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig
    );
    // msalInstance
    //   .handleRedirectPromise()
    //   .then((result) => {
    //     console.log(result);
    //     if (result) {
    //       // If result is truthy, your app returned from a redirect operation,
    //       // and it completed successfully
    //     } else {
    //       // If .then was called but result is falsey, that means your app is not returning
    //       // from a redirect operation (e.g. user visiting the site for the first time)
    //     }
    //   })
    //   .catch((error) => {
    //     // If .catch is called, this means your app was returning from a redirect operation
    //     // and an error occurred.
    //   });

    try {
      console.log("GET TOKEN");
      let tokenResponse = await msalInstance.acquireTokenSilent(request);
      console.log(tokenResponse);
      this.$store.commit("setAccessToken", tokenResponse.accessToken);
      this.$emitter.emit("setAccessToken", tokenResponse.accessToken);
    } catch (error) {
      console.error("Silent token acquisition failed. Using interactive mode");
      let tokenResponse = await msalInstance.acquireTokenPopup(request);
      console.log(
        `Access token acquired via interactive auth ${tokenResponse.accessToken}`
      );
      this.$store.commit("setAccessToken", tokenResponse.accessToken);
      this.$emitter.emit("setAccessToken", tokenResponse.accessToken);
    }
  },
});
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";
</style>
