import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import MiscModule from "@/store/modules/MiscModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ThemeModeModule,
    MiscModule,
  },
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: "a85d5087-c80c-4061-9e07-2094659c3aa7",
          authority:
            "https://login.microsoftonline.com/da9187df-9095-4835-a63d-d404e7d58612",
        },
        cache: {
          cacheLocation: "localStorage",
        },
      },
      accessToken: "",
    };
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
  },
});

export default store;
